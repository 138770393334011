<template>
  <div class="step">
    <div class="step__title">
      <div class="step__title--firstLine">Building an Assessment for:</div>
      <section class="step__title--secondLine">
        <Avatar :agent="assessment.agent" :showName="true" />
        <TpgButton
          v-if="isEditing && canDeleteDraft"
          id="delete-draft-button"
          flat
          @click="onDeleteDraftClicked"
          title="Delete Draft"
          data-test="delete-draft-button"
        >
          <span class="material-icons">delete</span> Delete
        </TpgButton>
      </section>
    </div>
    <div class="step__subtitle">Add general comments to finish up</div>
    <div class="step__heading">Manager Comments</div>
    <div class="final__commentLength">{{ commentLength }}/{{ charLimit.toLocaleString() }}</div>
    <textarea
      v-model="finalComment"
      class="final__comment"
      rows="5"
      placeholder="Add some closing comments"
      data-cy="final-comment-input"
      :maxlength="charLimit"
    ></textarea>
    <div class="step__heading">
      <div>Coaching Methods</div>
      <div class="allThatApply">(Select all that apply)</div>
    </div>
    <CoachingMethodSelection v-model="selectedCoachingMethods" :options="coachingMethods" />
    <div class="final__dates">
      <TpgDatepicker
        :modelValue="assessment.nextAssessmentDate"
        @update:modelValue="updateNextAssessmentDate"
        label="Next assessment date:"
        :lowerLimit="dateProps.lowerLimit"
        :inputFormat="dateProps.inputFormat"
        :openAboveField="true"
      />
    </div>
    <div class="step__actions">
      <TpgButton flat @click="$emit('back')"><i class="icon">arrow_back</i>Back</TpgButton>
      <div class="step__actions--right">
        <TpgButton flat @click="$emit('saveAsDraft')" data-cy="draft-button">Save as draft</TpgButton>
        <TpgButton @click="$emit('next')" data-cy="save-button"
          >Complete<i class="icon">check_circle_outline</i></TpgButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { TpgButton } from 'vue-components';
import Avatar from '../../Avatar.vue';
import TpgDatepicker from '../../TpgDatepicker.vue';
import CoachingMethodSelection from './CoachingMethodSelection.vue';
import { mapActions, mapState } from 'pinia';
import { useAssessmentStore, useCoachingStore, useConfirmDialogStore } from '@/stores';
import { useElementVisibility } from '@vueuse/core';
import { DELETE_DRAFT_CONFIRMATION } from '@/resources';

export default {
  name: 'FinalComment',
  emits: ['back', 'next', 'saveAsDraft', 'delete'],
  components: {
    TpgButton,
    Avatar,
    TpgDatepicker,
    CoachingMethodSelection
  },
  props: {
    assessment: Object
  },
  data: () => ({
    charLimit: 1e9,
    dateProps: {
      lowerLimit: new Date(),
      inputFormat: 'MM/dd/yyyy',
      canDeleteDraft: false
    }
  }),
  computed: {
    ...mapState(useCoachingStore, ['coachingMethods']),
    finalComment: {
      get() {
        return this.assessment.finalComment;
      },
      set(comment) {
        this.updateFinalComment(comment);
      }
    },
    commentLength() {
      return this.finalComment.length || 0;
    },
    selectedCoachingMethods: {
      get() {
        return this.assessment.coachingMethodIds;
      },
      set(ids) {
        this.updateCoachingMethodIds(ids);
      }
    },
    isEditing() {
      return this.assessment.coachingSessionId;
    }
  },
  methods: {
    ...mapActions(useConfirmDialogStore, { draftDeleteConfirm: 'open' }),
    ...mapActions(useAssessmentStore, [
      'updateFinalComment',
      'updateNextAssessmentDate',
      'updateCoachingMethodIds',
      'getCanDeleteDraftPermission'
    ]),
    async onDeleteDraftClicked() {
      const confirmed = await this.draftDeleteConfirm(DELETE_DRAFT_CONFIRMATION);
      if (confirmed) {
        this.$emit('delete');
      }
    }
  },
  async mounted() {
    this.elementVisibility = useElementVisibility(this.$refs.completeButton);
    this.canDeleteDraft = await this.getCanDeleteDraftPermission();
  },
  watch: {
    elementVisibility(newValue) {
      if (newValue) {
        this.isDisabled = true;

        setTimeout(() => {
          this.isDisabled = false;
        }, this.disableDelayInMilliseconds);
      }
    }
  }
};
</script>

<style lang="scss">
.final {
  &__commentLength {
    text-align: right;
    color: var(--gray-1);
    font-size: var(--font-size-small);
    margin-bottom: 4px;
    margin-top: -16px;
  }

  &__comment {
    width: calc(100% - 32px);
    margin-bottom: 40px;
  }

  &__dates {
    display: flex;
    gap: 32px;
    margin-bottom: 40px;
  }
}
</style>
